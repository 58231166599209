import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react'
import { InteractionType, AccountInfo } from '@azure/msal-browser'
import { toast } from 'react-toastify'

function LoadingComponent() {
	return <p>Authentication in progress...</p>
}

function ErrorComponent({ error }: { error: any }) {
	return <p>An error occurred: {error}</p>
}

export function AuthPage() {
	const { instance } = useMsal()

	const authRequest = {
		scopes: [
			'profile',
			'openid',
			'offline_access',
			'https://sustevoapp.onmicrosoft.com/6492784c-2ba4-4b41-81e5-22ad55ea6154/user_impersonation',
		],
		forceRefresh: true,
		grantType: 'authorization_code',
	}

	return (
		<MsalAuthenticationTemplate
			interactionType={InteractionType.Redirect}
			authenticationRequest={authRequest}
			loadingComponent={LoadingComponent}
			errorComponent={ErrorComponent}
		>
			<p>At least one account is signed in!</p>
		</MsalAuthenticationTemplate>
	)
}
