const Server = {
  endpoint: import.meta.env.VITE_ENDPOINT || "",
  clientendpoint: import.meta.env.VITE_CLIENTENDPOINT || "",
  project: import.meta.env.VITE_PROJECT || "",
  teamId: import.meta.env.VITE_TEAM_ID || "",
  userprofileCollectionId: import.meta.env.VITE_USERPROFILE_COLLECTION_ID || "",
  clientCollectionId: import.meta.env.VITE_CLIENT_COLLECTION_ID || "",
  projectCollectionId: import.meta.env.VITE_PROJECT_COLLECTION_ID || "",
  buildingCollectionId: import.meta.env.VITE_BUILDING_COLLECTION_ID || "",
  orderCollectionId: import.meta.env.VITE_ORDER_COLLECTION_ID || "",
  matterportCollectionId: import.meta.env.VITE_MATTERPORT_COLLECTION_ID || "",
  measureCollectionId: import.meta.env.VITE_MEASURE_COLLECTION_ID || "",
  measurepackCollectionId: import.meta.env.VITE_MEASUREPACK_COLLECTION_ID || "",
  measureconnectionCollectionId: import.meta.env.VITE_MEASURECONNECTION_COLLECTION_ID || "",
  schematicCollectionId: import.meta.env.VITE_SCHEMATIC_COLLECTION_ID || "",
  databaseID: import.meta.env.VITE_DATABASE_ID || "",
  energyDatabaseId: import.meta.env.VITE_ENERGY_DATABASE_ID || "",
  hourlyEnergyCollection: import.meta.env.VITE_HOURLY_ENERGY_COLLECTION || "",
  sustevoTeamId: import.meta.env.VITE_SUSTEVO_TEAMID || "",
  apiKey: import.meta.env.VITE_API_KEY || "",
};

export default Server;