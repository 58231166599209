import { FC, useEffect, useState } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { AuthPage } from '../modules/auth'
import { App } from '../App'
import { useIsAuthenticated } from '@azure/msal-react'
import { LayoutSplashScreen } from '../../_metronic/layout/core'

const { PUBLIC_URL } = import.meta.env

const AppRoutes: FC = () => {
	const isAuthenticated = useIsAuthenticated()
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		setLoading(false)
	}, [isAuthenticated])

	if (loading) {
		return <LayoutSplashScreen></LayoutSplashScreen>
	}

	return (
		<BrowserRouter basename={PUBLIC_URL}>
			<Routes>
				<Route element={<App />}>
					<Route path="error/*" element={<ErrorsPage />} />
					{isAuthenticated ? (
						<>
							<Route path="/*" element={<PrivateRoutes />} />
							<Route
								index
								element={<Navigate to="/dashboard" />}
							/>
						</>
					) : (
						<>
							<Route path="login" element={<AuthPage />} />
							<Route
								path="*"
								element={<Navigate to="/login" />}
							/>
						</>
					)}
				</Route>
			</Routes>
		</BrowserRouter>
	)
}

export { AppRoutes }
