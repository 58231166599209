/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl';
import { FC } from 'react';
import { AsideMenuItemWithSubMain } from './AsideMenuItemWithSubMain';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { AsideMenuItem } from './AsideMenuItem';

import { UserModel } from '../../../../app/services/models/UserModel';

export const AsideMenuMain: FC<{ user: UserModel }> = ({ user }) => {
	const intl = useIntl();
	return (
		<>
			{(user?.role !== "Localuser" || !user) && (
				<AsideMenuItem
					to='/dashboard'
					title='Home'
					fontIcon='bi-house'
					bsTitle={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
				/>
			)}
			<AsideMenuItem
				to='/sites'
				title='Sites'
				bsTitle='Sites'
				fontIcon='bi-box'
				iconColor='info'
			/>
			<AsideMenuItem
				to='/measures'
				title='Measures'
				bsTitle='Measures'
				fontIcon='bi-lightning-charge'
				iconColor='info'
			/>
			<AsideMenuItem
				to='/brands'
				title='Brands'
				bsTitle='Brands'
				fontIcon='bi-buildings'
			/>
			<AsideMenuItem
				to='/support-center/overview'
				title='Support'
				bsTitle='Support'
				fontIcon='bi-question-circle'
			/>
			{
				user && (user.role == 'Admin' || user.role == 'Superuser') && (
					<AsideMenuItem
						to='/panel'
						title='Panel'
						bsTitle='Panel'
						fontIcon='bi-shield-lock'
						iconColor='warning'
					/>
				)
			}
			{user && user.role == 'Admin' && (
				<AsideMenuItem
					to='/admin'
					title='Admin'
					bsTitle='Admin'
					fontIcon='bi-shield-lock'
					iconColor='danger'
				/>
			)}

			{/* <AsideMenuItemWithSubMain
        to='/crafted/pages'
        title='Crafted'
        fontIcon='bi-file-text'
        bsTitle='Crafted'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/profile/overview'
            title='Overview'
            bsTitle='Overview'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/pages/profile/projects'
            title='Projects'
            bsTitle='Projects'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            bsTitle='Campaigns'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            bsTitle='Documents'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
            bsTitle='Connections'
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
            bsTitle='Horizontal'
          />
          <AsideMenuItem
            to='/crafted/pages/wizards/vertical'
            title='Vertical'
            bsTitle='Vertical'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/accounts' title='Accounts' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/account/overview'
            title='Overview'
            hasBullet={true}
            bsTitle='Overview'
          />
          <AsideMenuItem
            to='/crafted/account/settings'
            title='Settings'
            hasBullet={true}
            bsTitle='Settings'
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/widgets' title='Widgets' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/widgets/lists'
            title='Lists'
            bsTitle='Lists'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/widgets/statistics'
            title='Statistics'
            bsTitle='Statistics'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/widgets/charts'
            title='Charts'
            bsTitle='Charts'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/widgets/mixed'
            title='Mixed'
            bsTitle='Mixed'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/widgets/tables'
            title='Tables'
            bsTitle='Tables'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/widgets/feeds'
            title='Feeds'
            bsTitle='Feeds'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/apps/chat' title='Chat' hasBullet={true}>
          <AsideMenuItem
            to='/apps/chat/private-chat'
            title='Private Chat'
            bsTitle='Private Chat'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/apps/chat/group-chat'
            title='Group Chart'
            bsTitle='Group Chart'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/apps/chat/drawer-chat'
            title='Drawer Chart'
            bsTitle='Drawer Chart'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='/error' title='Errors' hasBullet={true}>
          <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
          <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItem
          to='/apps/user-management/users'
          title='User management'
          hasBullet={true}
          bsTitle='User management'
        />
      </AsideMenuItemWithSubMain> 

      <AsideMenuItemWithSubMain
        to='/builder'
        title='Resources'
        bsTitle='Resources'
        fontIcon='bi-gear'
      >
        <AsideMenuItem to='/builder' title='Layout builder' fontIcon='bi-layers fs-3' />
        <AsideMenuItem
          to={import.meta.env.PREVIEW_DOCS_URL + '/docs/changelog'}
          outside={true}
          title={`Changelog ${import.meta.env.VERSION}`}
          fontIcon='bi-card-text fs-3'
        />
      </AsideMenuItemWithSubMain> */}
		</>
	);
}
