import axios from 'axios'
import config from '../utils/config'

import { ClientModel } from './models/ClientModel'
import { UserModel } from './models/UserModel'

const user = `${config.apiKey}/users/`

const client = `${config.apiKey}/clients/`

export async function getUser() {
	return (await axios.get<UserModel>(user + 'myself/')).data
}

export async function getUserById(id: number) {
	return (await axios.get<UserModel>(user + id + '/')).data
}

export async function updateMyself(data: Partial<UserModel>) {
	return (await axios.patch<UserModel>(user + 'myself/', data)).data
}

export async function getMyClient(client_id: number) {
	return (await axios.get<ClientModel>(client + client_id + '/')).data
}