import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { AsideMenuMain } from './AsideMenuMain';
import { DrawerComponent, ToggleComponent } from '../../../assets/ts/components';
import { UserModel } from '../../../../app/services/models/UserModel';

type Props = {
  asideMenuCSSClasses: string[];
  user: UserModel;
};

const AsideMenu: React.FC<Props> = ({ asideMenuCSSClasses, user }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      DrawerComponent.reinitialization();
      ToggleComponent.reinitialization();
    }, 50);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <div
      id='kt_aside_menu_wrapper'
      className='my-2 my-lg-5 pe-lg-n1'
      data-kt-scroll='false'
      data-kt-scroll-height='auto'
      data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
      data-kt-scroll-wrappers='#kt_aside, #kt_aside_menu'
      data-kt-scroll-offset='0px'
    >
      <div
        className='menu menu-column menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold'
        id='#kt_aside_menu'
        data-kt-menu='true'
      >
        <AsideMenuMain user={user} />
      </div>
    </div>
  );
};

export { AsideMenu };
